'use client'
import React from 'react';
import { ArrowRight, Sparkles } from 'lucide-react';
import { Link } from '@repo/i18n/navigation';
import { motion } from 'framer-motion';

const TechHero = () => {
  return (
    <div className="relative bg-slate-900 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <motion.div
          animate={{
            scale: [1, 1.1, 0.9, 1],
            x: [0, 30, -20, 0],
            y: [0, -50, 20, 0],
          }}
          transition={{
            duration: 7,
            repeat: Infinity,
            ease: "linear"
          }}
          // @ts-ignore
          className="absolute top-0 left-1/4 w-96 h-96 bg-blue-500/20 rounded-full mix-blend-multiply filter blur-3xl"
        />
        <motion.div
          animate={{
            scale: [1, 1.1, 0.9, 1],
            x: [0, 30, -20, 0],
            y: [0, -50, 20, 0],
          }}
          transition={{
            duration: 7,
            repeat: Infinity,
            ease: "linear",
            delay: 2
          }}
          // @ts-ignore
          className="absolute -top-4 right-1/4 w-96 h-96 bg-cyan-400/20 rounded-full mix-blend-multiply filter blur-3xl"
        />
        <motion.div
          animate={{
            scale: [1, 1.1, 0.9, 1],
            x: [0, 30, -20, 0],
            y: [0, -50, 20, 0],
          }}
          transition={{
            duration: 7,
            repeat: Infinity,
            ease: "linear",
            delay: 4
          }}
          // @ts-ignore
          className="absolute -bottom-8 left-1/3 w-96 h-96 bg-indigo-500/20 rounded-full mix-blend-multiply filter blur-3xl"
        />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-20 text-center">
        {/* Heading */}
        <motion.div 
          // @ts-ignore
          className="relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <motion.h1
            animate={{
              backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "linear"
            }}
            // @ts-ignore
            className="text-5xl md:text-7xl font-bold bg-gradient-to-r from-cyan-300 via-blue-400 to-indigo-400 bg-clip-text text-transparent mb-6"
            style={{ backgroundSize: '200% 200%' }}
          >
            AI-Powered Game Website Builder
          </motion.h1>
          <div className="absolute -top-6 right-1/4 transform rotate-12">
            <Sparkles className="w-6 h-6 text-cyan-400" />
          </div>
        </motion.div>

        {/* Subheading */}
        <p className="text-2xl text-cyan-400 font-semibold mb-4 flex items-center justify-center gap-4">
          <span className="relative group">
            Fast Finder
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-cyan-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </span>
          <span className="text-slate-600">·</span>
          <span className="relative group">
            Fast Website
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-cyan-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </span>
          <span className="text-slate-600">·</span>
          <span className="relative group">
            Fast Finance
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-cyan-400 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
          </span>
        </p>

        {/* Description */}
        <p className="text-xl text-slate-400 mb-12 max-w-3xl mx-auto leading-relaxed">
          Discover trending games before they go viral, launch SEO-optimized websites 
          in 30 minutes, and scale your game empire with zero coding.
        </p>

        {/* CTA Button */}
        <div className="flex justify-center space-x-4 mb-20">
          <Link 
            href="/dashboard/website-builder"
            className="group px-8 py-4 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-600 text-white text-lg font-semibold 
                     transition-all duration-200 hover:shadow-lg hover:shadow-cyan-500/25 
                     border border-cyan-500/20 flex items-center"
          >
            Start Building Now
            <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform duration-200" />
          </Link>
        </div>
        
        {/* Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {[
            { value: "30min", label: "Deploy Time" },
            { value: "¥0", label: "Dev Cost" },
            { value: "7day", label: "Trend Forecast" },
            { value: "3x", label: "ROI Boost" }
          ].map((stat, index) => (
            <div key={index} className="text-center bg-slate-800/50 backdrop-blur-lg p-8 rounded-xl border border-slate-700/50 
                                      transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-lg hover:shadow-cyan-500/10">
              <div className="text-4xl font-bold bg-gradient-to-r from-cyan-400 to-blue-400 bg-clip-text text-transparent mb-2">
                {stat.value}
              </div>
              <div className="text-slate-400">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechHero;