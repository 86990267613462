import { type Metrics } from '../types';

interface SuccessStoryCardProps {
  title: string;
  story: string;
  metrics: Metrics;
}

export const SuccessStoryCard = ({ title, story, metrics }: SuccessStoryCardProps) => (
  <div className="p-6 rounded-xl bg-white border border-gray-200 hover:shadow-lg transition-shadow">
    <h3 className="text-xl font-semibold mb-4 text-gray-900">{title}</h3>
    <p className="text-gray-600 mb-4">{story}</p>
    <div className="border-t border-gray-200 pt-4 mt-4">
      <div className="grid grid-cols-1 gap-2">
        {Object.entries(metrics).map(([key, value]) => (
          <div key={key} className="flex items-center justify-between">
            <span className="text-gray-500">{key}</span>
            <span className="font-semibold text-blue-600">{value}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
); 