import { Star } from 'lucide-react';

interface TechFeatureCardProps {
  title: string;
  features: string[];
}

export const TechFeatureCard = ({ title, features }: TechFeatureCardProps) => (
  <div className="p-6 rounded-xl bg-white border border-gray-200 hover:shadow-lg transition-shadow">
    <h3 className="text-xl font-semibold mb-4 text-gray-900">{title}</h3>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Star className="h-5 w-5 text-blue-500 mr-2 mt-1 flex-shrink-0" />
          <span className="text-gray-600">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
); 