import { type PricingPlan } from '../types';

interface PricingTableProps {
  plans: PricingPlan[];
}

export const PricingTable = ({ plans }: PricingTableProps) => (
  <div className="overflow-x-auto pb-6">
    <div className="inline-block min-w-full">
      <div className="rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-4 text-left text-sm font-semibold text-gray-900">Features</th>
              {plans.map((plan) => (
                <th 
                  key={plan.name}
                  scope="col" 
                  className={`px-6 py-4 text-center text-sm font-semibold text-gray-900 ${plan.isPopular ? 'bg-blue-50' : ''}`}
                >
                  {plan.name}
                  <span className="block text-sm font-normal text-gray-500">{plan.siteCount}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {/* Price Row */}
            <tr>
              <td className="px-6 py-4 text-sm text-gray-500">Price per site</td>
              {plans.map((plan) => (
                <td key={plan.name} className={`px-6 py-4 text-center ${plan.isPopular ? 'bg-blue-50' : ''}`}>
                  <div className="text-2xl font-bold text-gray-900">¥{plan.pricePerSite}</div>
                  <div className="text-sm text-green-600">{plan.discount}% off</div>
                  <div className="text-sm text-gray-500">
                    {plan.siteCount === 'Single site' ? `was ¥${plan.originalPrice}` : `Total: ¥${plan.totalPrice}`}
                  </div>
                </td>
              ))}
            </tr>
            {/* Features Rows */}
            <tr>
              <td className="px-6 py-4 text-sm text-gray-500">Website Amount</td>
              {plans.map((plan) => (
                <td key={plan.name} className={`px-6 py-4 text-center text-sm text-gray-900 ${plan.isPopular ? 'bg-blue-50' : ''}`}>
                  {plan.siteCount}
                </td>
              ))}
            </tr>
            
            <tr>
              <td className="px-6 py-4 text-sm text-gray-500">Support Level</td>
              {plans.map((plan) => (
                <td key={plan.name} className={`px-6 py-4 text-center text-sm text-gray-900 ${plan.isPopular ? 'bg-blue-50' : ''}`}>
                  {plan.supportLevel}
                </td>
              ))}
            </tr>
            
            {/* Action Row */}
            <tr>
              <td className="px-6 py-4 text-sm text-gray-500"></td>
              {plans.map((plan) => (
                <td key={plan.name} className={`px-6 py-4 text-center ${plan.isPopular ? 'bg-blue-50' : ''}`}>
                  <button 
                    className={`w-full px-4 py-2 text-sm rounded-lg ${
                      plan.name === 'Trial' 
                        ? 'border border-blue-600 text-blue-600 hover:bg-blue-50'
                        : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                  >
                    {plan.buttonText}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
); 