interface FAQItemProps {
  question: string;
  answer: string;
}

export const FAQItem = ({ question, answer }: FAQItemProps) => (
  <div className="border-b border-gray-200 pb-8">
    <h3 className="text-xl font-semibold mb-2 text-gray-900">{question}</h3>
    <p className="text-gray-600">{answer}</p>
  </div>
); 