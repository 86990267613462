interface StepCardProps {
  number: string;
  title: string;
  description: string;
}

export const StepCard = ({ number, title, description }: StepCardProps) => (
  <div className="relative p-6 rounded-xl bg-white border border-gray-200">
    <div className="absolute -top-4 -left-4 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 flex items-center justify-center text-white font-bold">
      {number}
    </div>
    <h3 className="text-xl font-semibold mb-2 mt-4 text-gray-900">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
); 